export const ReportEndpoints = {
  LIST_FISCAL_YEAR: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/factor-masters/years`,
  LIST_ANSERED_FISCAL_YEAR: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/factor-masters/used-years`,
  LIST_ENERGY_TYPE_WITH_USAGE_STATUS: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/energy-type-masters/usage-status`,

  ANSWER_INVOICE_CSV: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/answer-invoice/csv`,
  ANSWER_INVOICE_CSV_FOR_SINGLE_YEAR: (
    tenant_name_eng: string,
    fiscal_year: number | string
  ) => `/api/v1/${tenant_name_eng}/answer-invoice/csv/${fiscal_year}`,
};
