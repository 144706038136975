import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import { EmissionEndPoints } from "../emissions";
import type {
  Co2EmissionRequestParams,
  Co2EmissionResponseYearlyBody,
  Co2EmissionResponseMonthlyBody,
  SummaryGroupingType,
} from "../emissions";

export const co2EmissionSummaryMonthlyApi = (
  tenant_name_eng: string,
  grouping_type: SummaryGroupingType,
  params: Co2EmissionRequestParams
): Promise<CustomAxiosResponse<Co2EmissionResponseMonthlyBody>> => {
  return api.get<Co2EmissionResponseMonthlyBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION(tenant_name_eng, grouping_type),
    { params }
  );
};

export const co2EmissionSummaryYearlyApi = (
  tenant_name_eng: string,
  grouping_type: SummaryGroupingType,
  params: Co2EmissionRequestParams
): Promise<CustomAxiosResponse<Co2EmissionResponseYearlyBody>> => {
  return api.get<Co2EmissionResponseYearlyBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION(tenant_name_eng, grouping_type),
    { params }
  );
};
