// eslint-disable-next-line filenames/match-regex
import { TabsProps } from "antd";

import { TabKeyType, SummaryGroupingType } from "../emissions";

export const YEARLY_PERIOD_LENGTH = 10;

export const TRANSITION_TYPE = {
  monthly: "monthly",
  yearly: "yearly",
} as const;

export const GROUPING_TYPE = {
  scope: "scope",
  energyType: "energy_type",
  branch: "branch",
} as const;

export const GROUPING_TYPE_LABEL: Record<SummaryGroupingType, string> = {
  scope: "Scope別",
  energy_type: "エネルギー種別",
  branch: "拠点別",
} as const;

export const TAB_KEY = GROUPING_TYPE;

export const TAB_ITEMS: Required<TabsProps>["items"] = [
  {
    key: TAB_KEY.scope,
    label: GROUPING_TYPE_LABEL.scope,
  },
  {
    key: TAB_KEY.energyType,
    label: GROUPING_TYPE_LABEL.energy_type,
  },
  {
    key: TAB_KEY.branch,
    label: GROUPING_TYPE_LABEL.branch,
  },
];

export const RATE_SUMMARY_LABEL: Record<TabKeyType, string> = {
  scope: "",
  energy_type: "種別名",
  branch: "拠点名",
};

export const CHART_COLOR = ["#302751", "#694AD1", "#907CD9"];
export const CHART_COLOR_PREV = ["#C1B7DF", "#E5DCFF", "#F9F5FF"];

export const RATE_COLORS = [
  "#302751",
  "#694AD1",
  "#907CD9",
  "#C3B7EB",
  "#DFD8F5",
  "transparent",
];
