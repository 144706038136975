import qs from "query-string";
import { defer, LoaderFunction, LoaderFunctionArgs } from "react-router-dom";

import {
  ALL_BRANCH_ITEM_VALUE,
  ALL_BRANCH_SELECT_LABEL,
  ALL_YEAR_ITEM_VALUE,
} from "@app/constants/constants";
import {
  getBranches,
  getCo2EmissionSummaryMonthly,
  getCo2EmissionSummaryYearly,
  listAnsweredFiscalYear,
  resetListAnsweredYears,
} from "@app/redux";
import store from "@app/redux/store";

import {
  type EmissionLoaderReturn,
  type EmissionLoaderType,
  type EmissionSearchCondition,
  type TabKeyType,
  TAB_KEY,
  TRANSITION_TYPE,
  type Co2EmissionApiThunkParams,
  SEARCH_PARAM_KEYS,
} from "../../emissions";

type ParsedSearchParams = Omit<
  EmissionSearchCondition,
  "branchName" | "fiscalYear"
> & {
  fiscalYear?: EmissionSearchCondition["fiscalYear"];
};

/**
 * searchParamsをパースしてEmissionSearchParamを返す
 * @param request
 * @returns EmissionSearchParam
 */
const parseSearchParams = (
  request: LoaderFunctionArgs["request"]
): ParsedSearchParams => {
  const url = new URL(request.url);
  const {
    [SEARCH_PARAM_KEYS.FISCAL_YEAR]: fiscalYearParam,
    [SEARCH_PARAM_KEYS.BRANCH_ID]: branchIdParam,
    [SEARCH_PARAM_KEYS.TRANSITION_TYPE]: transition_type,
    [SEARCH_PARAM_KEYS.TAB_GROUP]: tabParam,
  } = qs.parse(url.search, { parseNumbers: true });

  // パラメータの検証
  const tabGroup: TabKeyType =
    typeof tabParam === "string" &&
    Object.values(TAB_KEY).some(v => v === tabParam)
      ? (tabParam as TabKeyType)
      : TAB_KEY.scope;

  const parseYear = (param: unknown) => {
    if (typeof param === "number") return param;
    // 数値以外は未指定として扱う
    // 未指定の場合はBEから返却される最新年度を利用する
    return undefined;
  };

  const parseBranch = (param: unknown) => {
    if (typeof param === "number") return param;
    return ALL_BRANCH_ITEM_VALUE;
  };

  return {
    groupingType: tabGroup,
    transitionType:
      transition_type === TRANSITION_TYPE.yearly
        ? transition_type
        : TRANSITION_TYPE.monthly,
    fiscalYear: parseYear(fiscalYearParam),
    branchId: parseBranch(branchIdParam),
  };
};

const emissionTransitionLoader = async (
  condition: ParsedSearchParams
): Promise<EmissionLoaderType> => {
  const { transitionType, fiscalYear, branchId, groupingType } = condition;

  await store.dispatch(listAnsweredFiscalYear());
  await store.dispatch(getBranches());

  const yearList = store.getState().report.listAnsweredYears?.years;
  if (!yearList?.length) throw Error("Not found answered fiscal year");
  if (fiscalYear !== ALL_YEAR_ITEM_VALUE && fiscalYear) {
    if (!yearList.includes(fiscalYear)) throw Error("fiscal year not found");
  }
  const { branches } = store.getState().branches;
  if (!branches.length) throw Error("get branches failed");

  const branchName =
    branchId === ALL_BRANCH_ITEM_VALUE
      ? ALL_BRANCH_SELECT_LABEL
      : branches.find(item => item.id === branchId)?.name;
  if (!branchName) {
    throw Error("branch not found");
  }

  const params: Co2EmissionApiThunkParams = {
    fiscal_year: fiscalYear !== ALL_YEAR_ITEM_VALUE ? fiscalYear : undefined,
    branch_id: branchId !== ALL_BRANCH_ITEM_VALUE ? branchId : undefined,
    groupingType,
  };
  if (transitionType === TRANSITION_TYPE.yearly) {
    // 年次推移
    await store.dispatch(getCo2EmissionSummaryYearly(params)).unwrap();
    // note: 年次推移の場合、res.fiscal_yearは取得した年度範囲の修了年度になるが、
    // ここで返却するのは年度ドロップダウンの選択値
    return {
      ...condition,
      fiscalYear: fiscalYear ?? ALL_YEAR_ITEM_VALUE,
      branchName,
    };
  }

  const res = await store
    .dispatch(getCo2EmissionSummaryMonthly(params))
    .unwrap();
  if (!res.fiscal_year) throw Error("fiscal year not found in response");

  return {
    ...condition,
    fiscalYear: res.fiscal_year,
    branchName,
  };
};

/**
 * page loader
 * 排出量ページのデータを取得する
 *  - パラメータなし: scopeごとの最新年度の月次推移データを取得
 *  - fiscal_year に数値以外: 無視
 *  - fiscal_year に年度リストに含まれない年度指定: エラー
 *  - branch_id に数値以外: 無視
 *  - branch_id に無効なid指定: エラー
 *  - 不正なtab_group: scope に変換
 *  - 不正なtransition_type: monthly に変換
 *
 * @param params
 * @returns
 */
const emissionPageLoader: LoaderFunction = async params => {
  const { tenant_name_eng } = params.params;
  // テナント名が無い場合はエラー
  if (!tenant_name_eng) throw Error();

  const url = new URL(params.request.url);
  if (!url.search) {
    // パラメータなしの場合は再取得するようにリセット
    store.dispatch(resetListAnsweredYears());
  }

  // 検索条件の取得
  const condition = parseSearchParams(params.request);

  // promise 作成
  // 推移データ
  const transitionPromise = emissionTransitionLoader(condition);

  const loaderData: EmissionLoaderReturn = {
    deferData: transitionPromise,
  };
  return defer(loaderData);
};

export default emissionPageLoader;
