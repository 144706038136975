import { getTenantEng } from "../../tenants/helpers/tenants.helpers";

const tenant_name_eng = getTenantEng();

export enum EmissionRoutePaths {
  EMISSION = "/:tenant_name_eng/emissions",
}

export const EmissionPaths = {
  EMISSION: () => `/${tenant_name_eng}/emissions`,
};

export const SEARCH_PARAM_KEYS = {
  TAB_GROUP: "tab_group",
  TRANSITION_TYPE: "transition_type",
  FISCAL_YEAR: "fiscal_year",
  BRANCH_ID: "branch_id",
} as const;
