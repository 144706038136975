import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { getTenantEng } from "../../tenants/helpers/tenants.helpers";
import { EMISSION_KEY } from "../constants/emissions.keys";
import {
  co2EmissionSummaryMonthlyApi,
  co2EmissionSummaryYearlyApi,
  TRANSITION_TYPE,
} from "../emissions";
import type { Co2State, Co2EmissionApiThunkParams } from "../emissions";

const initialState: Co2State = {
  isLoading: true,
};

const tenant_name_eng = getTenantEng();

/**
 * Co2排出量サマリー取得(単年度指定)
 */
export const getCo2EmissionSummaryMonthly = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission`,
  (params: Co2EmissionApiThunkParams) => {
    const { groupingType, ...rest } = params;
    return co2EmissionSummaryMonthlyApi(tenant_name_eng, groupingType, {
      ...rest,
      transaction_type: TRANSITION_TYPE.monthly,
    });
  }
);

/**
 * Co2排出量サマリー取得(全ての年度)
 * NOTE:
 * 初期ロード10年分からShiftされた場合、現在はまるまる10年差し替えているが、
 * 差分を取得して継ぎ足すThunkを作ってもいいかもしれない
 */
export const getCo2EmissionSummaryYearly = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission_multiple_years`,
  (params: Co2EmissionApiThunkParams) => {
    const { groupingType, ...rest } = params;
    return co2EmissionSummaryYearlyApi(tenant_name_eng, groupingType, {
      ...rest,
      transaction_type: TRANSITION_TYPE.yearly,
    });
  }
);

export const emissionSlice = createSlice({
  name: EMISSION_KEY,
  initialState,
  reducers: {
    beginEmissionLoading: state => {
      state.isLoading = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCo2EmissionSummaryMonthly.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCo2EmissionSummaryYearly.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCo2EmissionSummaryMonthly.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getCo2EmissionSummaryYearly.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getCo2EmissionSummaryMonthly.fulfilled, (state, action) => {
      const res = action.payload;
      const { branch, total_value, proportion } = res;

      state.data = {
        condition: {
          transactionType: TRANSITION_TYPE.monthly,
          groupingType: action.meta.arg.groupingType,
          fiscalYear: res.fiscal_year,
          fiscalYearTo: 0,
          fiscalYearFrom: 0,
          branch,
        },
        total_value,
        proportion,
        transitions: {
          monthData: res.transitions,
        },
      };

      state.isLoading = false;
    });
    builder.addCase(getCo2EmissionSummaryYearly.fulfilled, (state, action) => {
      const res = action.payload;
      const { branch, total_value, proportion } = res;

      state.data = {
        condition: {
          transactionType: TRANSITION_TYPE.yearly,
          groupingType: action.meta.arg.groupingType,
          fiscalYearFrom: res.fiscal_year_from,
          fiscalYearTo: res.fiscal_year_to,
          fiscalYear: res.fiscal_year_to,
          branch,
        },
        total_value,
        proportion,
        transitions: {
          yearlyData: res.transitions,
        },
      };

      state.isLoading = false;
    });
  },
});

export { EMISSION_KEY, initialState as emissionInitialState };
export const { beginEmissionLoading } = emissionSlice.actions;
export const emissionReducer = emissionSlice.reducer;
